import React from 'react';
import { graphql } from 'gatsby';
import * as Ink from '@carta/ink';
import Layout from '../components/Layout';

import Header from '../components/Header/Header';
import PreviewCard from '../components/Common/PreviewCard';

const Deprecation = ({
  data: {
    allDeprecation: { nodes },
  },
}) => {
  const sortedDeprecation = nodes.sort((a, b) => a.frontmatter.title.localeCompare(b.frontmatter.title));
  return (
    <Layout pageTitle="Deprecation">
      <Header
        title="Deprecation Efforts"
        subtitle="Ink is cleaning technical debt and deleting older components from our codebase. Here you can find help migrating from old components to their newer versions"
      />
      <Ink.Box top="20" bottom="20px">
        <Ink.Text>
          Legacy code is frustrating for all Carta, and ink is no exception. There are several components with
          deprecated and modern implementations - as a result, the ink team needs to maintain both in order for legacy
          pages to continue to function properly.
        </Ink.Text>

        <Ink.Text>
          In light of the 2022 visual rebrand, the ink team decided it was not worthwhile to keep legacy components
          updated with the new visuals. Instead, we are aiming to delete these deprecated components entirely, which
          means teams and products with outdated components will need to migrate to their newer versions as soon as
          possible.
        </Ink.Text>

        <Ink.Text>
          We also from time to time see the need to deprecate props or prop values in order to provide a better semantic
          and cohesive API, this way we also list these here and their respective replacements.
        </Ink.Text>
      </Ink.Box>
      <Ink.Tiles columns={[1, 2, 2, 3, 4]}>
        {sortedDeprecation.map(node => (
          <PreviewCard
            key={node.frontmatter.title}
            title={node.frontmatter.title}
            text={node.frontmatter.subtitle}
            actionLabel="View guide"
            actionHref={`https://ink.carta.com/deprecation/${node.slug}`}
            previewWithCheckerboard
          />
        ))}
      </Ink.Tiles>
    </Layout>
  );
};

export default Deprecation;

export const query = graphql`
  query DeprecationQuery {
    allDeprecation: allMdx(
      filter: { fileAbsolutePath: { regex: "/content/deprecation/" }, slug: { regex: "/^((?!migration-guides/).)*$/" } }
    ) {
      nodes {
        frontmatter {
          title
          subtitle
          replacing
          replacementComponent
          componentUsageGuide
        }
        slug
      }
    }
  }
`;
